import React, { useContext } from "react";
import orderContext from "../OrderContext";
import { useWizard } from "react-use-wizard";
import { Link } from 'gatsby';
import useHoney from "../../utlis/useHoney";
import { StyledDivFooterWizard, StyledButtonFooterProgresBar, StyledButtonFooterFinishButton } from "src/components/ProgressBar/ProgresBar.styles";

const FooterWizard = ({ flavored }) => {
    const { isValidForm, handleSetCheck, checkDelivery, toogleModalOpen, orders } = useContext(orderContext);
    const [order] = orders;
    const { clearOrder } = useHoney(flavored);

    const handleCheckAndOpenModal = async () => {
        await handleSetCheck(true);
        await toogleModalOpen();
    };

    const handleSendMailAndNext = async () => {
        await handleSetCheck(true);
        await nextStep();
    };

    const {
        nextStep,
        previousStep,
        isLoading,
        isLastStep,
        isFirstStep,
        activeStep
    } = useWizard();

    const isDisabled = isLoading || isLastStep;

    const renderStepButtons = () => {
        if (activeStep === 0) {
            return (
              <StyledButtonFooterProgresBar
                ownWidth="140"
                onClick={() => (checkDelivery ? nextStep() : toogleModalOpen())}
                disabled={isDisabled || order.length === 0}
              >
                  Dalej
              </StyledButtonFooterProgresBar>
            );
        }

        if (activeStep === 1) {
            return (
              <StyledButtonFooterProgresBar
                onClick={isValidForm ? handleSendMailAndNext : handleCheckAndOpenModal}
                disabled={isDisabled}
              >
                  Dalej
              </StyledButtonFooterProgresBar>
            );
        }

        if (activeStep === 2) {
            return (
              <StyledButtonFooterFinishButton
                onClick={() => clearOrder()}
                as={Link}
                to="/"
                disabled={isDisabled}
              >
                  Koniec
              </StyledButtonFooterFinishButton>
            );
        }

        return null;
    };

    return (
      <StyledDivFooterWizard>
          <StyledButtonFooterProgresBar
            ownWidth="140"
            onClick={() => previousStep()}
            disabled={isLoading || isFirstStep}
          >
              Wstecz
          </StyledButtonFooterProgresBar>

          {renderStepButtons()}
      </StyledDivFooterWizard>
    );
};

export default FooterWizard;
