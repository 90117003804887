import React, { useContext, useEffect, useState } from 'react';
import {
    StyledDeliverAndPayment,
    StyledBottom,
    StyledButton,
    StyledDelivery,
    StyledInput,
    StyledLinks,
    StyledListElement,
    StyledSearchInpost,
    StyledSummary
} from "./HoneyOrder.styles";
import formatMoney from "../../utlis/formatMoney";
import calculateOrderTotal from "../../utlis/calculateOrderTotal";
import orderContext from "../OrderContext";
import PropTypes from "prop-types";

const DeliverAndPayment = ({ flavored, isOrder }) => {
    const {
        orders,
        deliver,
        handleSetDelivery,
        handleCheckDelivery,
        selectedPoint,
        handleSetPayment,
        handleSetPrice,
        payment,
        toogleModalInpostOpen
    } = useContext(orderContext);

    const deliveryOptions = [
        { name: 'Inpost paczkomat', price: { Przelew: 21.00, "Za pobraniem": 21.00 } },
        { name: 'Inpost kurier', price: { Przelew: 26.00, "Za pobraniem": 35.00 } },
        { name: 'Odbiór osobisty', price: { Przelew: 0.00, "Za pobraniem": 0.00 } }
    ];

    const [selectedDelivery, setSelectedDelivery] = useState({ name: deliver.name || '', price: deliver.price || 0 });
    const [selectedPayment, setSelectedPayment] = useState(payment.payment || 'Przelew');
    const [isPay, setIsPay] = useState(false);

    useEffect(() => {
        handleSetDelivery(selectedDelivery);
    }, [selectedDelivery]);

    useEffect(() => {
        handleSetPayment({ payment: selectedPayment });
        if(selectedDelivery.name !== '') {
            handleSetPrice({price:getSelectedDeliveryOption(selectedDelivery.name).price});
            // console.log("test", getSelectedDeliveryOption(selectedDelivery.name).price);
        }
        setIsPay(!!selectedPayment);
    }, [selectedPayment]);

    useEffect(() => {
        const isDeliveryValid = selectedDelivery.name && (selectedDelivery.name !== 'Inpost paczkomat' || selectedPoint.length > 0);
        handleCheckDelivery(isDeliveryValid && isPay);
    }, [selectedDelivery, isPay, selectedPoint]);

    const handleDeliveryChange = (e) => {
        const selectedOption = deliveryOptions.find(option => option.name === e.target.value);
        setSelectedDelivery({ name: selectedOption.name, price: selectedOption.price[selectedPayment] });
    };

    const handlePaymentChange = (e) => {
        const newPayment = e.target.value;
        setSelectedPayment(newPayment);
        setSelectedDelivery(prevState => {
            const selectedOption = deliveryOptions.find(option => option.name === prevState.name);
            return { ...prevState, price: selectedOption ? selectedOption.price[newPayment] : prevState.price };
        });
    };
    const getSelectedDeliveryOption = (deliveryName) => {
        const selectedOption = deliveryOptions.find(option => option.name === deliveryName);
        return { price: selectedOption.price[selectedPayment] };

    };


    return (
      <StyledDeliverAndPayment>
          {!isOrder && (
            <StyledBottom>
                <StyledDelivery>
                    <h3>Wybierz dostawę:</h3>
                    {deliveryOptions.map(({ name, price }) => (
                      <StyledListElement key={name}>
                          <label>
                              <StyledInput
                                id={price[selectedPayment]}
                                type="radio"
                                name="delivery"
                                value={name}
                                checked={selectedDelivery.name === name}
                                onChange={handleDeliveryChange}
                              />
                              {name}
                          </label>
                          <p>{formatMoney(price[selectedPayment] === null ? "Przelew" : price[selectedPayment])}</p>
                          {name === 'Inpost paczkomat' && selectedDelivery.name === name && (
                            <>
                                <StyledSearchInpost>
                                    <input type="text" placeholder="Wybrany paczkomat" value={selectedPoint} readOnly />
                                </StyledSearchInpost>
                                <StyledLinks>
                                    <StyledButton Big onClick={toogleModalInpostOpen}>
                                        {selectedPoint.length > 0 ? 'Zmień paczkomat' : 'Wybierz paczkomat'}
                                    </StyledButton>
                                </StyledLinks>
                            </>
                          )}
                      </StyledListElement>
                    ))}
                </StyledDelivery>

                <StyledDelivery>
                    <h3>Wybierz płatność:</h3>
                    {['Przelew', 'Za pobraniem'].map((payMethod) => (
                      <StyledListElement key={payMethod}>
                          <label>
                              <StyledInput
                                type="radio"
                                name="payment"
                                value={payMethod}
                                disabled={selectedDelivery.name === 'Odbiór osobisty' || (selectedDelivery.name === 'Inpost paczkomat' && payMethod === 'Za pobraniem')}
                                checked={selectedPayment === payMethod}
                                onChange={handlePaymentChange}
                              />
                              {payMethod}
                          </label>
                      </StyledListElement>
                    ))}
                </StyledDelivery>
            </StyledBottom>
          )}

          <StyledSummary>
              <h3>Do zapłaty: {formatMoney(calculateOrderTotal(orders[0], flavored, selectedDelivery.price))}</h3>
              {isOrder && (
                <>
                    <p>Metoda dostawy: {deliver.name} - {formatMoney(deliver.price)}</p>
                    <p>Metoda zapłaty: {selectedPayment}</p>
                </>
              )}
          </StyledSummary>
      </StyledDeliverAndPayment>
    );
};

DeliverAndPayment.propTypes = {
    flavored: PropTypes.array.isRequired,
    isOrder: PropTypes.bool
};

export default DeliverAndPayment;
